/* ./src/fonts.css */

.font-inter {
    font-family: 'Inter', sans-serif;
}

.font-ryomen {
    font-family: 'Ryomen', sans-serif;
}

.zizo {
    font-family: 'zizo';
}

.ledscreen {
    font-family: 'LEDSCREEN';
}

.ledscreen-bold {
    font-family: 'LEDSCREEN';
    font-weight: bold;
}

.affinity {
    font-family: 'Affinity';
}

.geometrica {
    font-family: 'Geometrica';
}

.cygre {
    font-family: 'cygre';
}

@font-face {
    font-family: 'Ryomen';
    src: url('../public/fonts/Ryomen.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'zizo';
    src: url('../public/fonts/zizo.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometrica';
    src: url('../public/fonts/geometrica/regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometrica';
    src: url('../public/fonts/geometrica/bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Affinity';
    src: url('https://qskgg4o.app-palma.teide.app/api/files/weg6asel72priur/aan1ei9y3519dfb/affinity_z4LgTfXHsh.woff?token=') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LEDSCREEN';
    src: url('https://qskgg4o.app-palma.teide.app/api/files/weg6asel72priur/ueq9yquqj6g8tz6/ledscreen_Rt5RkqMfER.woff?token=') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LEDSCREEN';
    src: url('https://qskgg4o.app-palma.teide.app/api/files/weg6asel72priur/ueq9yquqj6g8tz6/ledscreen_Rt5RkqMfER.woff?token=') format('woff');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'cygre';
    src: url('../public/fonts/cygre/Cygre-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cygre';
    src: url('../public/fonts/cygre/Cygre-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}