/* CardComponent.css */

body.modal-open {
    overflow: hidden;
}

.card-container {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    perspective: 1000px;
}

.card {
    width: 100%;
    aspect-ratio: 61.5 / 88;
    position: relative;
    overflow: hidden;
    z-index: 1;
    touch-action: none;
    border-radius: 5% / 3.5%;
    box-shadow: -5px -5px 5px -5px var(--color1), 5px 5px 5px -5px var(--color2),
        -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent,
        0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s ease, box-shadow 0.2s ease;
    will-change: transform, filter;
    background-color: #040712;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform-origin: center;
    cursor: pointer;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card:hover {
    box-shadow: -20px -20px 30px -25px var(--color1), 20px 20px 30px -25px var(--color2),
        -7px -7px 10px -5px var(--color1), 7px 7px 10px -5px var(--color2),
        0 0 13px 4px rgba(255, 255, 255, 0.3), 0 55px 35px -20px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.1s ease-out;
}

.card:before,
.card:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    transition: all 0.33s ease;
}

.card:before {
    background-position: 50% 50%;
    background-size: 300% 300%;
    background-image: linear-gradient(115deg,
            transparent 0%,
            var(--color1) 25%,
            transparent 47%,
            transparent 53%,
            var(--color2) 75%,
            transparent 100%);
    opacity: 0.5;
    filter: brightness(0.5) contrast(1);
    z-index: 1;
}

.card:after {
    opacity: 0.75;
    background-image: url('https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/api/files/f887avv7ydmjckl/akphvv9944jasvf/sparkles_ZImtpXBtcF.gif'), url('https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/api/files/f887avv7ydmjckl/smg3iru5jl8qlio/holo.png'),
        linear-gradient(125deg,
            #ff008450 15%,
            #fca40040 30%,
            #ffff0030 40%,
            #00ff8a20 60%,
            #00cfff40 70%,
            #cc4cfa50 85%);
    background-position: 50% 50%;
    background-size: 160%;
    background-blend-mode: overlay;
    z-index: 2;
    filter: brightness(1) contrast(1);
    mix-blend-mode: color-dodge;
}

.card:hover:after {
    filter: brightness(1) contrast(1);
    opacity: 1;
}

.card:hover:before {
    background-image: linear-gradient(110deg,
            transparent 25%,
            var(--color1) 48%,
            var(--color2) 52%,
            transparent 75%);
    background-position: 50% 50%;
    background-size: 250% 250%;
    opacity: 0.88;
    filter: brightness(0.66) contrast(1.33);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: zoom-out;
    padding: 10px;
}

.enlarged-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    object-fit: contain;
    cursor: default;
    width: auto;
    height: auto;
}