/* ./src/style.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    font-size: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}